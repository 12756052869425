<mat-menu #addMenu>
  @for (blockType of quickBlockTypes; track blockType) {
    <a mat-menu-item (click)="addBlock(blockType)">
      <mat-icon>{{blockType.icon}}</mat-icon>
      {{blockType.label}}
    </a>
  }
  <a mat-menu-item (click)="showPicker()">
    <mat-icon>more_horiz</mat-icon>
    More
  </a>
</mat-menu>

@if (!parentEditor) {
  <div class="toolbar">
    <mat-button-toggle-group [(ngModel)]="editorMode">
      <mat-button-toggle value="normal" aria-label="Edit">
        <mat-icon>edit</mat-icon>
        Edit
      </mat-button-toggle>
      <mat-button-toggle value="reorder" aria-label="Reorder">
        <mat-icon>drag_handle</mat-icon>
        Reorder
      </mat-button-toggle>
      <mat-button-toggle value="preview" aria-label="Preview">
        <mat-icon>preview</mat-icon>
        View
      </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="spacer"></div>

    @if (enableNotices) {
      @if (editorMode === 'normal') {
        <div class="notice">
          <mat-icon>info</mat-icon>
          <div>
            <strong>Spacing is inaccurate while editing</strong><br/>
            More spacing is added while editing to allow for editor controls. To see an accurate
            rendering of the page, use Preview.
          </div>
        </div>
      } @else if (editorMode === 'preview') {
        @if (isMultiColumn) {
          <div class="notice">
            <mat-icon>info</mat-icon>
            <div>
              <strong>Inline preview is not exact</strong><br/>
              Some block features such as stretching from edge to edge are clipped within the editor
              for the best experience. Preview the page directly on TYT.com for the best accuracy.
            </div>
          </div>
        }
      } @else if (editorMode === 'reorder') {
        @if (isMultiColumn) {
          <div class="notice">
            <mat-icon>info</mat-icon>
            <div>
              <strong>Reordering will use the single column mode</strong><br/>
              This page uses the Sidebar. When viewed on mobile devices, the sidebar column is
              merged with the main column. The order of blocks is determined by the mobile layout.
            </div>
          </div>
        }
      }
    }

  </div>
}

@if (editorMode === 'preview') {
  <tyt-content-view [blocks]="blocks"></tyt-content-view>
} @else {
  <!-- NOT PREVIEWING -->

  @if (editorMode === 'reorder') {
    <!-- SINGLE COLUMN, REORDERING -->
    <div
      #reorderList
      class="block-list single-column reordering"
      [class.reordering-multi]="isMultiColumn"
      cdkDropList
      [cdkDropListConnectedTo]="otherGroupedDropLists"
      [cdkDropListData]="blocks"
      cdkDropListLockAxis="y"
      cdkDropListOrientation="vertical"
      [cdkDropListEnterPredicate]="checkForEnter"
      (cdkDropListDropped)="drop($event)"
      >
      @for (block of blocks; track block.id || block) {
        @if (blockType(block).isLayout === true) {
          <div class="reorder-container" cdkDrag (cdkDragStarted)="onDragStart($event)">
            <div class="layout-reorder-handle">
              <mat-icon>drag_handle</mat-icon>
              {{ blockType(block).label }}
            </div>
            <div class="reordering-indicator manual">
              <mat-icon>open_with</mat-icon>
            </div>
            <tyt-content-block-editor [block]="block" />
          </div>
        } @else {
          <div class="reorder-container" cdkDrag (cdkDragStarted)="onDragStart($event)">
            <tyt-content-block [block]="block">
              <div class="content-block-move-placeholder" *cdkDragPlaceholder></div>
            </tyt-content-block>
            <div class="reordering-indicator">
              <mat-icon class="main-icon">open_with</mat-icon>

              <div class="controls">
                <button mat-icon-button matTooltip="Move up by one block" (click)="moveBlockUp(block)">
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Move down by one block" (click)="moveBlockDown(block)">
                  <mat-icon>arrow_downward</mat-icon>
                </button>
              </div>
            </div>
          </div>
        }
      }
    </div>
  }

  <!-- MULTI COLUMN VIEW WHEN MULTI COLUMN MODE IS ENABLED, USED IN MEDIA QUERIES FOR DESKTOP -->
  @if (editorMode !== 'reorder') {
    <div class="multi-column">
      <div class="block-list primary-column">
        @for (block of primaryBlocks; track blockIdentity($index, block)) {
          <tyt-content-block-editor-ui
            [block]="block"
            [style.order]="singleColumnIndex(block)"
            />
        }
        <div class="actions" [style.order]="blocks.length">
          <button
            mat-icon-button
            [matMenuTriggerFor]="addMenu"
            (click)="onClickAddBeforeLast(false)"
            class="add"
            matTooltip="Add new block to main column"
            [disabled]="locked"
            >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="block-list secondary-column" [class.empty]="secondaryBlocks.length === 0">
        @for (block of secondaryBlocks; track blockIdentity($index, block)) {
          <tyt-content-block-editor-ui
            [block]="block"
            [style.order]="singleColumnIndex(block)"
            />
        }
        <div class="actions" [style.order]="blocks.length">
          <button
            mat-icon-button
            [matMenuTriggerFor]="addMenu"
            (click)="onClickAddBeforeLast(true)"
            class="add"
            matTooltip="Add new block to sidebar"
            [disabled]="locked"
            >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  }
}