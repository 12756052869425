<div class="header">
  <h1>Choose Block</h1>
  <mat-form-field appearance="outline">
    <mat-label>Search</mat-label>
    <span matPrefix>
      <mat-icon>search</mat-icon>
    </span>

    <input #searchInput type="text" matInput [(ngModel)]="query" />
  </mat-form-field>
</div>

<div class="block-list">
  @for (block of allBlockTypes; track block; let i = $index) {
    <button class="block-type" (click)="insert(block)" [class.selected]="selected === i">
      <div class="block-type-label">
        {{block.label}}
      </div>
      <div class="preview"
        [attr.data-fade]="block.previewSettings?.fade"
        [attr.data-horizontal-align]="block.previewSettings?.horizontalAlign"
        [attr.data-vertical-align]="block.previewSettings?.verticalAlign"
        >
        <div class="overlay"></div>
        @if (block.preview) {
          <tyt-content-block [block]="previewOf(block)"></tyt-content-block>
        }
      </div>
    </button>
  }
</div>