import { CdkDrag, CdkDragDrop, CdkDragEnd, CdkDragEnter, CdkDragExit, CdkDragStart, CdkDropList, DragRef, DropListRef, Point, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostBinding, InjectionToken, Input, Output, ViewChild, inject } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Shell } from '@tytapp/common';
import { ContentBlock } from '@tytapp/content/content-block';
import { ContentBlockType } from '@tytapp/content/content-block-type';
import { v4 as uuid } from 'uuid';
import { ContentBlockPickerComponent } from '../content-block-picker/content-block-picker.component';
import { ContentBlocksService } from '../content-blocks.service';
import { Subject } from 'rxjs';
import { isClientSide } from '@tytapp/environment-utils';
import { MatSnackBar } from '@angular/material/snack-bar';

export const IN_EDITOR = new InjectionToken<boolean>('IN_EDITOR');

@Component({
    selector: 'tyt-content-editor',
    templateUrl: './content-editor.component.html',
    styleUrls: ['./content-editor.component.scss'],
    providers: [
        { provide: IN_EDITOR, useValue: true }
    ]
})
export class ContentEditorComponent {
    private blockTypes = inject(ContentBlocksService);
    private shell = inject(Shell);
    readonly parentEditor = inject(ContentEditorComponent, { optional: true, skipSelf: true }) || undefined;

    ngOnInit() {
        if (isClientSide()) {
            document.addEventListener('keydown', this.keyDownListener);
            document.addEventListener('keyup', this.keyUpListener);
        }

        setTimeout(() => this.rootEditor.groupedEditors.push(this));
    }

    ngOnDestroy() {
        setTimeout(() => {
            let groupIndex = this.rootEditor.groupedEditors.indexOf(this);
            this.rootEditor.groupedEditors.splice(groupIndex, 1);
        });

        if (isClientSide()) {
            document.removeEventListener('keydown', this.keyDownListener);
            document.removeEventListener('keyup', this.keyUpListener);
        }
    }

    onDragStart($event: CdkDragStart<any>) {
        // BUG-WORKAROUND
        //
        // Angular will only initialize the parent positions (needed for nested list dropping to work)
        // for the start list. Here we just find all other lists and setup their parent position cache
        // to work around this.
        for (let list of this.otherGroupedDropLists)
            list._dropListRef._cacheParentPositions();
    }

    get rootEditor(): ContentEditorComponent {
        return this.parentEditor?.rootEditor ?? this;
    }

    private groupedEditors: ContentEditorComponent[] = [];

    get groupedDropLists() {
        if (this.rootEditor !== this)
            return this.rootEditor.groupedDropLists;

        // IMPORTANT: Change after check unless you use _reorderList,
        // the mechanics of set reorderList() address this.
        return this.groupedEditors.map(x => x._reorderList).filter(x => x);
    }

    get otherGroupedDropLists() {
        return this.groupedDropLists.filter(x => x !== this.reorderList);
    }

    private _reorderList: CdkDropList<ContentBlock[]>;

    @ViewChild('reorderList', { read: CdkDropList })
    get reorderList(): CdkDropList<ContentBlock[]> {
        return this._reorderList;
    }

    set reorderList(value) {
        setTimeout(() => this._reorderList = value);
    }

    @Input() topLevel = false;
    @Input() preferredTypes: string[];
    @Input() locked: boolean;
    @Input() enableNotices = true;
    @Input() blocks: ContentBlock[];
    @Output() autoSave = new Subject<void>();
    @Output() addMenu = new EventEmitter<MatMenu>();

    @Input() @HostBinding('attr.data-theme')
    theme: 'dark' | 'light';

    keyDownListener = (ev: KeyboardEvent) => {
        if (ev.target instanceof HTMLInputElement || ev.target instanceof HTMLTextAreaElement || ev.target instanceof HTMLButtonElement || ev.target instanceof HTMLAnchorElement)
            return;

        if (ev.key === 'Control')
            this.ctrlDown = true;
        else if (ev.key === 'Shift')
            this.shiftDown = true;
        else if (ev.key === 'Alt')
            this.altDown = true;
        else
            return;

        ev.preventDefault();
    };

    keyUpListener = (ev: KeyboardEvent) => {
        if (ev.target instanceof HTMLInputElement || ev.target instanceof HTMLTextAreaElement || ev.target instanceof HTMLButtonElement || ev.target instanceof HTMLAnchorElement)
            return;

        if (ev.key === 'Control')
            this.ctrlDown = false;
        else if (ev.key === 'Shift')
            this.shiftDown = false;
        else if (ev.key === 'Alt')
            this.altDown = false;
        else
            return;

        ev.preventDefault();
    };

    @Input('editorMode')
    private _editorMode: 'normal' | 'reorder' | 'preview' = 'normal';

    get editorMode() {
        if (this.parentEditor)
            return this.parentEditor.editorMode;

        return this._editorMode;
    }

    set editorMode(value) {
        this._editorMode = value;
    }

    addBefore: ContentBlock;
    addAsSecondary: boolean;
    ctrlDown = false;
    shiftDown = false;
    altDown = false;

    get primaryBlocks() { return this.blocks?.filter(x => !x.secondary) ?? []; }
    get secondaryBlocks() { return this.blocks?.filter(x => x.secondary) ?? []; }
    get isMultiColumn() { return this.secondaryBlocks.length > 0; }

    @HostBinding('class.has-multi-column')
    get shouldUseTwoColumn() {
        return this.isMultiColumn && this.editorMode !== 'reorder';
    }

    @ViewChild('addMenu', { static: true })
    set _addMenu(menu: MatMenu) {
        this.addMenu?.emit(menu);
    }

    get quickBlockTypes() {
        if (this.preferredTypes) {
            return this.preferredTypes.map(id => this.allBlockTypes().find(t => t.id === id)).filter(x => x);
        }

        return this.allBlockTypes().slice(0, 5);
    }

    moveBlockUp(block: ContentBlock) {
        let pos = this.blocks.indexOf(block);
        if (pos <= 0)
            return;

        this.blocks.splice(pos, 1);
        this.blocks.splice(pos - 1, 0, block);
    }

    moveBlockDown(block: ContentBlock) {
        let pos = this.blocks.indexOf(block);
        if (pos < 0 || pos >= this.blocks.length - 1)
            return;

        this.blocks.splice(pos, 1);
        this.blocks.splice(pos + 1, 0, block);
    }

    allBlockTypes() {
        return this.blockTypes.all();
    }

    blockIdentity(index: number, block: ContentBlock) {
        return block.id || block;
    }

    addBlockBefore(existingBlock: ContentBlock, newType: ContentBlockType) {
        this.addBefore = existingBlock;
        this.addBlock(newType, { secondary: existingBlock.secondary });
    }

    onClickAddBeforeLast(secondary = false) {
        this.addBefore = undefined;
        this.addAsSecondary = secondary;
    }

    blockType(block: ContentBlock) {
        return this.blockTypes.getFromBlock(block);
    }

    blockTypeId(block: ContentBlock) {
        return block.type;
    }

    addBlock(type: ContentBlockType, options: Partial<ContentBlock> = {}) {
        const block = JSON.parse(JSON.stringify(<ContentBlock>{
            ...(type.template ?? {}),
            type: type.id,
            id: uuid(),
            secondary: this.addAsSecondary,
            __initialEditorMode: type.editable ? 'edit' : 'preview',
            ...options
        }));

        let beforeIndex = this.blocks.indexOf(this.addBefore);

        if (beforeIndex < 0) {
            this.blocks.push(block);
        } else {
            this.blocks.splice(beforeIndex, 0, block);
        }
    }

    removeBlock(block: ContentBlock) {
        if (!this.shiftDown) {
            if (!confirm("Are you sure you want to remove this block?"))
                return;
        }

        let index = this.blocks.indexOf(block);

        if (index >= 0)
            this.blocks.splice(index, 1);
    }

    delayAutoSave() {
        this.autoSave.next();
    }

    snackbar = inject(MatSnackBar);

    drop(event: CdkDragDrop<ContentBlock[]>) {
        console.log(`DROP`);
        console.dir(this.otherGroupedDropLists);

        if (event.previousContainer === event.container) {
            //this.snackbar.open('moved within same container', undefined, { duration: 3000 });
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            //this.snackbar.open('moved into another container', undefined, { duration: 3000 });
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    showPicker() {
        this.shell.showDialog(ContentBlockPickerComponent, block => this.addBlock(block));
    }

    singleColumnIndex(block: ContentBlock) {
        return this.blocks.indexOf(block);
    }

    private insideBox(box: DOMRect, point: Point) {
        if (!box)
            return false;
        if (!point)
            return false;

        return box.x <= point.x && box.x + box.width >= point.x
            && box.y <= point.y && box.y + box.height >= point.y;
    }

    checkForEnter = (drag: CdkDrag<any>, drop: CdkDropList<any>) => {
        if (1)
            return true;

        let element = drag.getPlaceholderElement();
        let existingContainer = drag.dropContainer.element.nativeElement;
        let newContainer = drop.element.nativeElement;
        let rect = existingContainer.getBoundingClientRect();
        let elementRect = element.getBoundingClientRect();

        let pos: Point = { x: elementRect.x, y: elementRect.y };
        let newContainerNested = newContainer.contains(existingContainer);
        let stillInsideOldContainer = this.insideBox(rect, pos);

        console.log(
            `CHECK: rect=${JSON.stringify(rect)}, pos=${JSON.stringify(pos)} `
            + `stillInside=${stillInsideOldContainer}, nested=${newContainerNested}`
        );

        if (this.insideBox(rect, drag.freeDragPosition) && newContainerNested)
            return false;

        return true;
    };
}
